<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12">
                    <h3>My Information</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    
                    <v-radio-group 
                        row 
                        v-model="userLoggedGetters.themeColor" 
                        @change="changeTheme()"
                        style="width: 100%"
                    >
                        <v-radio label="Dark"  :value="1" color="var(--color__main)"></v-radio>
                        <v-radio label="Light" :value="2" color="var(--color__main)"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>First Name</label>
                    <v-text-field
                        v-model="userLoggedGetters.firstName" 
                        :rules=[validations.required]
                        single-line
                        disabled
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>Last Name</label>
                    <v-text-field
                        v-model="userLoggedGetters.lastName" 
                        single-line
                        disabled
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>E-Mail</label>
                    <v-text-field
                        v-model="userLoggedGetters.email"
                        single-line
                        disabled
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>State</label>
                    <v-combobox
                        v-model="userLoggedGetters.stateSelected"
                        :items="listState"
                        item-text="codeDescription"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>City</label>
                    <v-text-field
                        v-model="userLoggedGetters.city" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>Address</label>
                    <v-text-field
                        v-model="userLoggedGetters.address" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <br />

            <ActionButtons
                :request="userLoggedGetters"
                :showButtons="{
                    cancel: false,
                    save: true
                }"
                @save="saveProfile"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            listState: [],

            validations: {
                required: required,
            }
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            disableButton() {
                return this.validForm() === false;
            },
        },

        methods: {

            async getLists() {
                
                this.listState = await this.$store.dispatch("stateModule/List");
            },

            changeTheme() {
                
                if (this.userLoggedGetters.themeColor === 1)  { 
                    //dark
                    this.$vuetify.theme.dark = true;
                }
                else { 
                    // light
                    this.$vuetify.theme.dark = false;
                }
            },

            async saveProfile() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    let requestProfile = {
                        idUser: this.userLoggedGetters.id, 
                        stateID: this.userLoggedGetters.stateSelected.id,
                        city: this.userLoggedGetters.city,
                        address: this.userLoggedGetters.address,
                        themeColor: this.userLoggedGetters.themeColor
                    }

                    const result = await this.$store.dispatch("userModule/UpdateProfile", requestProfile);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();

            this.hideLoading();
        }
    })
</script>
